import React from "react";
import { Link } from "gatsby";
import kebabCase from "lodash.kebabcase";

export default function ListHero({ edges }) {
  return (
    <>
      {edges.map((edge) => (
        <article className="w-80 pr-4 my-2">
          <div className="items-center uppercase leading-relaxed">
            <time>{edge.node.fields.date}</time>
          </div>
          <Link to={`/${edge.node.fields.slug}`}>
            <img src={edge.node.fields.images}
            className="my-2 w-full object-cover h-48" alt={edge.node.fields.title} loading="lazy"/>
            <h2 className="text-xl my-2">{edge.node.fields.title}</h2>
          </Link>
          <ul className="flex flex-wrap gap-4">
          {edge.node.frontmatter.tags &&
            edge.node.frontmatter.tags.map((tag) => (
              <li key={tag} className="bg-green-50 text-green-700 text-xs tracking-normal uppercase rounded-lg px-2 py-1 my-2">
                <Link to={`/tag/${kebabCase(tag)}`}>{tag}</Link>
              </li>
          )).slice(0, 1)}
          </ul>
        </article>
      ))}
    </>
  );
}
