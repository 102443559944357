import React from "react";
import { PageProps, graphql } from "gatsby";
import Header from "@/components/Header";
import Container from "@/components/Container";
import ListNote from "@/components/ListNote";
import ListHero from "@/components/ListHero";
import { Link } from "gatsby";
import { GatsbySeo, SitelinksSearchBoxJsonLd } from "gatsby-plugin-next-seo";
import {
  url, 
  LOCALE, 
  HERO_TITLE,
  HERO_SUBTITLE,
  SITE_TITLE,
  SITE_DESCRIPTION,
} from "../../config";
import Layout from "@/components/Layout";

const IndexPage = ({ data, pageContext }) => {
  const { edges } = data.allMdx;
  const Featured = data.Featured;

  return (
  <>
    <GatsbySeo
      title={SITE_TITLE}
      description={SITE_DESCRIPTION}
      canonical={url}
      language={LOCALE}
    />
    <SitelinksSearchBoxJsonLd
      url={url}
      searchHandlerQueryStringUrl='https://www.google.com/search?q=site%3Afandomnesia.com+'
    />
    <Layout>
      <div className="my-10">
        <Link to="https://news.google.com/s/CBIwwamJs2Q" className="px-3 py-1 text-black text-xs font-semibold uppercase font-mono tracking-wide leading-none bg-amber-400 rounded-full">
          Subscribe
        </Link>
        <h1 className="text-black text-3xl md:text-5xl mt-4 md:text-5xl leading-normal md:leading-snug transition-all ease-in-out">
          {HERO_TITLE}
        </h1>
      </div>
      <div className="w-full h-96 shrink-0 flex overflow-x-auto relative origin-center scale-100 transition transform duration-500">
        <div className="absolute bottom-0 flex">
          <ListHero edges={Featured.edges} />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-6">
        <ListNote edges={edges} />
      </div>
    </Layout>
  </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexTemplateQuery {
    allMdx(
      filter: {
        frontmatter: { publish: { ne: false } }
        fileAbsolutePath: { regex: "/vault/" }
      }
      limit: 20
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          ...postList
        }
      }
    }
    Featured: allMdx(
      filter: {
        frontmatter: { publish: { ne: false }, tags: { in: ["featured"] } }
        fileAbsolutePath: { regex: "/vault/" }
      }
      limit: 5
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          ...postList
        }
      }
    }
  }
`;
